/*
 * @Author: ahaofuture ahaofuture0705@163.com
 * @Date: 2023-01-06 17:36:36
 * @LastEditors: ahaofuture ahaofuture0705@163.com
 * @LastEditTime: 2023-01-11 08:54:27
 * @FilePath: \investment_management_system\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vant from 'vant';
import 'amfe-flexible';
import '@utils/css'
import '@utils/com'
Vue.config.productionTip = false
Vue.prototype.$baseUrl = '';

Vue.use(vant)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
