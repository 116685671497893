<!--
 * @Author: ahaofuture ahaofuture0705@163.com
 * @Date: 2023-01-06 17:36:36
 * @LastEditors: ahaofuture ahaofuture0705@163.com
 * @LastEditTime: 2023-01-11 09:09:01
 * @FilePath: \investment_management_system\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <div class="app-container">
      <div class="app-container_router">
        <router-view />
      </div>
      <tool-tabbar v-if="isTabbar"></tool-tabbar>
    </div>
  </div>
</template>
<script>
export default {
  name: "app",
  data() {
    return {
      isTabbar: false,
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.isTabbar = val.meta.isTabbar;
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.isTabbar = this.$router.currentRoute.meta.isTabbar;
  },
};
</script>
<style lang="scss" scoped>
#app {
  width: 100vw;
  min-height: 100vh;
  .app-container {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    @include Flex($direction: column);

    &_router {
      flex: 1;
      width: 100%;
      /* overflow: auto; */
      /* 隐藏滚动条 */

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
</style>
