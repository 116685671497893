<!--
 * @Author: ahaofuture ahaofuture0705@163.com
 * @Date: 2023-01-09 10:41:56
 * @LastEditors: ahaofuture ahaofuture0705@163.com
 * @LastEditTime: 2023-01-11 09:29:39
 * @FilePath: \investment_management_system\src\components\common\list\ListInterspaceResource.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE

-->
<template>
  <!-- <img class="image" :src="imageUrl" alt="" /> -->
  <img
    class="image"
    :src="src ? imageUrl : defaultImage"
    alt=""
    @click="showImage"
    :onerror="`this.onerror='';this.src='${defaultImage}'`"
  />
</template>
<script>
import defaultImage from "@icons/icons_ico_error.png";
const BASE_URL = process.env.VUE_APP_BASE_URL;
import { ImagePreview } from "vant";
export default {
  name: "tool-image",
  data() {
    return {
      defaultImage: defaultImage,
    };
  },
  computed: {
    imageUrl() {
      return BASE_URL + this.$props.src;
    },
  },
  props: {
    src: {
      type: String,
      default: () => {
        return "";
      },
      required: true,
    },
    onceClick: {
      type: Boolean,
      default: false,
    },
    srcArray: {
      type: Array,
      default: () => {
        return [];
      },
    },
    onceIndex: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  methods: {
    showImage() {
      if (!this.$props.onceClick) return;
      ImagePreview({
        images: this.$props.srcArray
          ? this.$props.srcArray.map((e) => BASE_URL + e)
          : [this.$props.src].map((e) => BASE_URL + e),
        startPosition: this.$props.onceIndex,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.image {
  width: 100%;
  height: 100%;
}
</style>