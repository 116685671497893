/*
 * @Author: ahaofuture ahaofuture0705@163.com
 * @Date: 2023-01-09 08:46:01
 * @LastEditors: ahaofuture ahaofuture0705@163.com
 * @LastEditTime: 2023-01-11 11:33:47
 * @FilePath: \investment_management_system\src\utils\iconsList.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Icon_active_1 from '@icons/icons_tabs_active_1.png'
import Icon_unactive_1 from '@icons/icons_tabs_unactive_1.png'
import Icon_active_2 from '@icons/icons_tabs_active_2.png'
import Icon_unactive_2 from '@icons/icons_tabs_unactive_2.png'
import Icon_active_3 from '@icons/icons_tabs_unactive_3.png'
import Icon_active_4 from '@icons/icons_tabs_active_4.png'
import Icon_unactive_4 from '@icons/icons_tabs_unactive_4.png'
import Icon_active_5 from '@icons/icons_tabs_active_5.png'
import Icon_unactive_5 from '@icons/icons_tabs_unactive_5.png'
export default {
    iconList: [
        {
            path: 'investmentattraction',
            name: '招商动态',
            active: Icon_active_1,
            unActive: Icon_unactive_1,
        },
        {
            path: 'newstreeprofile',
            name: '春江概况',
            active: Icon_active_2,
            unActive: Icon_unactive_2,
        },
        {
            path: 'interspaceresourcesimple',
            name: '一键直达',
            active: Icon_active_3,
            unActive: Icon_active_3,
        },
        {
            path: 'industrialpolicy',
            name: '产业政策',
            active: Icon_active_4,
            unActive: Icon_unactive_4,
        },
        {
            path: 'mapposition',
            name: '地图位置',
            active: Icon_active_5,
            unActive: Icon_unactive_5,
        },
    ]
}
