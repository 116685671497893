/*
 * @Author: ahaofuture ahaofuture0705@163.com
 * @Date: 2023-01-06 17:36:36
 * @LastEditors: ahaofuture ahaofuture0705@163.com
 * @LastEditTime: 2023-01-10 14:50:59
 * @FilePath: \investment_management_system\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/guide',
  },
  // 引导页
  {
    path: '/guide',
    name: "guide",
    component: () => import('@views/InvestmentAttraction/guide.vue'),
    meta: {
      title: '存量招商',
      isTabbar: false
    }
  },
  // 招商动态
  {
    path: '/investmentattraction',
    name: "investmentattraction",
    component: () => import('@views/InvestmentAttraction/index.vue'),
    meta: {
      title: '招商动态',
      isTabbar: true
    }
  },
  // 春江概况
  {
    path: '/newstreeprofile',
    name: "newstreeprofile",
    component: () => import('@views/NewStreeProfile/index.vue'),
    meta: {
      title: '春江概况',
      isTabbar: true
    }
  },
  // 一键直达-简化的一级列表
  {
    path: '/interspaceresourcesimple',
    name: "interspaceresourcesimple",
    component: () => import('@views/InterspaceResource/simple.vue'),
    meta: {
      title: '一键直达',
      isTabbar: true
    }
  },
  // 一键直达
  {
    path: '/interspaceresource/:id',
    name: "interspaceresource",
    component: () => import('@views/InterspaceResource/index.vue'),
    meta: {
      title: '一键直达',
      isTabbar: true
    }
  },
  // 一键直达  - 详情
  {
    path: '/interspaceresourcedetail/:id',
    name: "interspaceresourcedetail",
    component: () => import('@con/detail/DetailInterspaceresource.vue'),
    meta: {
      title: '一键直达',
      isTabbar: true
    }
  },
  // 产业政策
  {
    path: '/industrialpolicy',
    name: "industrialpolicy",
    component: () => import('@views/IndustrialPolicy/index.vue'),
    meta: {
      title: '产业政策',
      isTabbar: true
    }
  },
  // 地图位置
  {
    path: '/mapposition',
    name: "mapposition",
    component: () => import('@views/MapPosition/index.vue'),
    meta: {
      title: '地图位置',
      isTabbar: true
    }
  },
  // 招商动态-详情
  {
    path: '/dynamic_info',
    name: "dynamic_info",
    component: () => import('@views/InvestmentAttraction/dynamic_info.vue'),
    meta: {
      title: '详情',
      isTabbar: true
    }
  },
  // 产业政策-详情
  {
    path: '/policy_info',
    name: "policy_info",
    component: () => import('@views/IndustrialPolicy/policy_info.vue'),
    meta: {
      title: '详情',
      isTabbar: true
    }
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
