<!--
 * @Author: ahaofuture ahaofuture0705@163.com
 * @Date: 2023-01-09 09:57:23
 * @LastEditors: ahaofuture ahaofuture0705@163.com
 * @LastEditTime: 2023-01-12 10:00:25
 * @FilePath: \investment_management_system\src\components\tool\ToolSwiper.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="swiper-container">
    <van-swipe
      v-if="list.length > 0"
      :autoplay="3000"
      class="swiper-container_swiper"
      indicator-color="#f9952b"
      @change="(idx) => (current = idx)"
    >
      <van-swipe-item v-for="(item, index) in list" :key="index">
        <tool-image :src="item" class="swiper-container_swiper_image" />
      </van-swipe-item>
      <template
        v-if="[$props.indicatorType].includes('custom') && list.length > 1"
        #indicator
      >
        <div class="custom-indicator">{{ current + 1 }}/{{ list.length }}</div>
      </template>
    </van-swipe>
    <tool-image v-else :src="item" class="swiper-container_swiper_image" />
  </div>
</template>
<script>
export default {
  name: "tool-swiper",
  data() {
    return {
      current: 0,
    };
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
      required: true,
    },
    indicatorType: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.swiper-container {
  height: 100%;

  &_swiper {
    height: 100%;

    &_image {
      width: 100%;
      height: 100%;
    }
  }

  .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
  }

  .custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>
