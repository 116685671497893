<!--
 * @Author: ahaofuture ahaofuture0705@163.com
 * @Date: 2023-01-07 09:34:25
 * @LastEditors: ahaofuture ahaofuture0705@163.com
 * @LastEditTime: 2023-01-10 16:56:29
 * @FilePath: \investment_management_system\src\components\tool\Tabbar.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="Tabbar">
    <div class="Tabbar_list">
      <router-link
        :class="
          index == 2
            ? 'Tabbar_list_item-active Tabbar_list_item'
            : 'Tabbar_list_item'
        "
        v-for="(item, index) in Tabbars.iconList"
        :key="index"
        :to="{ name: item.path }"
      >
        <img
          :src="[item.path].includes(RouteName) ? item.active : item.unActive"
          alt=""
          class="Tabbar_list_item_icon"
        />
        <span
          class="Tabbar_list_item_name"
          :class="{ active: index !== 2 && [item.path].includes(RouteName) }"
        >
          {{ item.name }}
        </span>
      </router-link>
    </div>
  </div>
</template>
<script>
import iconsList from "@utils/iconsList.js";
export default {
  name: "tool-tabbar",
  data() {
    return {
      Tabbars: iconsList,
      RouteName: "",
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.RouteName =
          val.matched[0].path.replace("/", "") || "investmentattraction";
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.RouteName = this.$router.currentRoute.name;
  },
};
</script>
<style lang="scss" scoped>
.Tabbar {
  height: 56px;
  width: 100%;
  box-shadow: 0px 0px 5px 1px rgba(180, 193, 203, 0.5);
  position: fixed;
  background: #ffffff;
  bottom: 0;
  left: 0;
  padding: 0px 20px;
  // z-index: 1;

  &_list {
    height: 100%;
    @include Flex($align: center, $justify: space-between);
    &_item {
      @include Flex($direction: column, $align: center);
      text-decoration: none;
      gap: 5px;
      &_icon {
        width: 24px;
        height: 24px;
      }
      &_name {
        color: #b9bcbf;
        font-size: 12px;
        font-weight: 400;
      }
    }
    &_item-active {
      left: 50%;
      width: 70px;
      height: 70px;
      background: linear-gradient(180deg, #3dc989 0%, #3c94d0 100%);
      border-radius: 100%;
      margin-bottom: 25px;
      @include FlexCenter;
      span {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
.active {
  color: #3c94d0 !important;
}
</style>
