<!--
 * @Author: ahaofuture ahaofuture0705@163.com
 * @Date: 2023-01-10 14:21:38
 * @LastEditors: ahaofuture ahaofuture0705@163.com
 * @LastEditTime: 2023-01-10 14:36:11
 * @FilePath: \investment_management_system\src\components\tool\ToolLoading.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <van-loading
    class="loading"
    :style="{ height: height, width: '100%' }"
    v-if="loading"
    type="spinner"
    color="#B3B5B9"
  >
    加载中...
  </van-loading>
</template>
<script>
export default {
  name: "tool-loading",
  data() {
    return {};
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
      required: true,
    },
    height: {
      type: String,
      default: () => {
        return "100vh";
      },
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.loading {
  @include Flex($direction: column, $align: center, $justify: center);
  gap: 5px;
  .van-loading__text {
    font-size: 14px;
  }
}
</style>