/*
 * @Author: ahaofuture ahaofuture0705@163.com
 * @Date: 2023-01-11 08:44:59
 * @LastEditors: ahaofuture ahaofuture0705@163.com
 * @LastEditTime: 2023-01-11 09:27:21
 * @FilePath: \Investment_management_system\src\utils\com.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import ToolImage from '@com/tool/ToolImage.vue'
import ToolLoading from '@com/tool/ToolLoading.vue'
import ToolSwiper from '@com/tool/ToolSwiper.vue'
import ToolTabbar from '@com/tool/ToolTabbar.vue'
const componentArray = [
    ToolImage,
    ToolLoading,
    ToolSwiper,
    ToolTabbar
]
let i = -1; while (++i < componentArray.length) Vue.component(componentArray[i].name, componentArray[i])